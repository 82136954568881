/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 
 function debounce(func, wait, immediate) {
         // console.log('func', func)
         // console.log('wait', wait)
         // console.log('immediate', immediate)
       wait = typeof wait === undefined ? 20 : wait;
       immediate = typeof immediate === undefined ? 20 : immediate;
       
      var timeout;

      return function() {
          var context = this, args = arguments;
          var later = function() {
        timeout = null;
        if (!immediate) {func.apply(context, args);}
          };
          var callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) {func.apply(context, args);}
      };
 }

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var ASAM = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        ASAM.ajax.init();
        ASAM.ajax.ajax_filters();
        ASAM.nav_menus.init();
        ASAM.videos.init();
        ASAM.sliders.init();
        ASAM.forms.init();
        ASAM.forms.custom_select();
        ASAM.lightbox.init();
        ASAM.image_zoom.init();
        ASAM.init.scroll_to();
        ASAM.init.adjust_main_menu();
        ASAM.init.fix_footer();
        ASAM.init.content_images_loaded();
        // window.addEventListener('popstate', function(e) {
        //   console.log('popstate', e);
        // });

        $(window).on('resize', function() {
          debounce(ASAM.forms.search_field());
          debounce(ASAM.init.fix_footer());
          debounce(ASAM.init.content_images_loaded());
          debounce(ASAM.ajax.ajax_filters());
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    init: {
      scroll_to: function() {
        var $scroll_btns = $('.scroll-nav');

        $scroll_btns.each(function() {
          var $btn = $(this);

          ASAM.init.update_scroll_btn($btn);

          $(window).on('scroll', function() {
            debounce(ASAM.init.update_scroll_btn($btn));
          });

          $btn.click(function() {
            var pos = $btn.attr('data-scroll');


            if (pos === 'undefined') {
              return;
            }

            if (pos === 'page') {
              ASAM.helpers.smoothScroll(pos);
            }
            else {
              ASAM.helpers.smoothScroll(pos);
            }

          });
        });
      },

      update_scroll_btn: function($btn) {
        var pos = $btn.data('scroll'),
            window_bottom = window.pageYOffset + window.innerHeight,
            footer_h = $('.site-footer').height();

        if ($btn.hasClass('scroll-nav-page')) {
          var $btn_text = $btn.find('.scroll-nav-text');

          if ( document.body.offsetHeight > (window.innerHeight + window.pageYOffset + footer_h) ) {

            if ( (window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - (footer_h + 100)) ) {
              $btn
                .attr('data-scroll', 'top')
                .css('bottom', footer_h + 30);

              $btn_text.text('Go to top');

            }
            else {
              $btn
                .attr('data-scroll', 'page')
                .css('bottom', '60px');

              $btn_text.text('Scroll');
            }

            $btn.css('opacity', '1');
          }


        }
      },

      fix_footer: function() {
        var $footer = $('.site-footer'),
            $body_content = $('.content-wrap'),
            footer_h = $footer.outerHeight(),
            body_content_h = $body_content.outerHeight();

        if (window.matchMedia("(min-width: 992px)").matches && (body_content_h + footer_h) < window.innerHeight) {
          $footer.addClass('fixed-footer');
        }
        else {
          $footer.removeClass('fixed-footer');
        }
      },

      adjust_main_menu: function() {
        var $site_header = $('#site-header');


        if (window.matchMedia("(max-height: 830px)").matches) {

        }
      },

      content_images_loaded: function() {
        var $content = $('.post-content');

        if ($content.length) {
          $content.imagesLoaded(function() {
            ASAM.init.fix_footer();
          });
        }
      }

    },

    image_zoom: {
      init: function() {
        var $image = $('.img-zoom');

        $image.magnify({
          afterLoad: function() {
            console.log('image zoom loaded');
            ASAM.init.fix_footer();
          }
        });

      }
    },

    zoombox: {
      init: function() {
        var $zoombox = $('img.light-zoom');

        $zoombox.lightzoom({
          zoomPower : 2,
          glassSize: 300,
          glassHeight: 300,
          glassWidth: 500
        });
      }
    },

    lightbox: {
      init: function() {
        var $lightbox_wrappers = $('.lightbox'),
            icon = '<svg width="31px" height="30px" viewBox="0 0 31 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Instructions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Desktop-HD" transform="translate(-156.000000, -177.000000)" fill="#E4002B"><g id="Group-18" transform="translate(156.000000, 177.000000)"><g id="Group-4">                    <polygon id="Path" transform="translate(15.500000, 15.000000) rotate(-45.000000) translate(-15.500000, -15.000000) " points="-4.04347826 16.3636364 -4.04347826 13.6363636 35.0434783 13.6363636 35.0434783 16.3636364"></polygon><polygon id="Path" transform="translate(15.500000, 15.000000) rotate(-135.000000) translate(-15.500000, -15.000000) " points="-4.04347826 16.3636364 -4.04347826 13.6363636 35.0434783 13.6363636 35.0434783 16.3636364"></polygon></g></g></g></g></svg>';

        $lightbox_wrappers.each(function() {
          var $lightbox_wrap = $(this);

          var options = {
                          nav : false,
                          showCounter : false,
                          loop : false,
                          widthRatio : 1,
                          heightRatio : 2,
                          closeText : icon,
                      };

          var $lightbox = $lightbox_wrap.simpleLightbox(options);
        });
      }
    },

    videos: {
      init: function() {
        var $videos = $('.video-player');

        if ($videos.length) {
          $videos.each(function(e) {
            var $video = $(this);

            $video.on('click', function() {
              ASAM.videos.get_video($video);
            });
          });
        }
      },
      get_video: function($video) {
        var video_src = $video.data('embed-src'),
            video_id = $video.data('embed'),
            $video_iframe = '';

        if (video_src === 'vimeo') {
          var video_url = 'https://player.vimeo.com/video/' + video_id,
              $url = 'https://vimeo.com/api/oembed.json?url=' + video_url + '&autoplay=true';

          var jqxhr = $.get( $url, function(data) {
                // console.log( "get success" );
              })
              .done(function(data) {
                // console.log(data);
                ASAM.videos.add_video(data.html, $video);
              })
              .fail(function() {
                // console.log( "error" );
              })
              .always(function() {
                // console.log( "finished" );
              });
        }

        if (video_src === 'youtube') {
          var iframe = document.createElement("iframe"),
              embed = 'https://www.youtube.com/embed/' + video_id + '?autoplay=1';
          
          iframe.setAttribute("src", embed);
          iframe.setAttribute("frameborder", "0");
          iframe.setAttribute("allowfullscreen", "1");
          
          ASAM.videos.add_video(iframe, $video);
        }

        // console.log($video_iframe);



      },
      add_video: function($iframe, $video) {
        var $placeholder = $video.find('.placeholder');

          $placeholder.fadeOut();
          $video.append($iframe).addClass('playing-video');
      }
    },

    nav_menus: {
      vars: {
        nav: '.nav',
        mainNav: '.main-menu__nav',
        menuItem : '.menu-item',
        activeClass : 'active',
        openClass : 'menu-item-open',
        siteContainer : '.site-container',
        items: [],
        menuAnimateLoop: ''
      },
      init: function() {
        var $nav = $(ASAM.nav_menus.vars.mainNav).find(ASAM.nav_menus.vars.nav);

        ASAM.nav_menus.main_nav();
        ASAM.nav_menus.nav_controls($nav);

        if ( $(ASAM.nav_menus.vars.siteContainer).hasClass('site-container-fixed') ) {
          ASAM.nav_menus.animate_menu($nav);
        }
      },
      main_nav: function() {
        var $main_nav = $(ASAM.nav_menus.vars.mainNav),
            $sub_menus = $main_nav.find('.menu-item-has-children'),
            $collapse_els = $main_nav.find('.collapse');

        $sub_menus.each(function() {
          var $sub_menu = $(this),
              $link = $sub_menu.children('.link'),
              $collapse = $sub_menu.children('.collapse');

          $link.click(function(e) {
            e.preventDefault();
            $collapse_els.collapse('hide');
            $collapse.collapse('toggle');
          });

          $collapse.on('show.bs.collapse', function(e) {
            var $el = $(e.target),
                action = 'open';

            ASAM.nav_menus.toggle_active($el, action);

          });

          $collapse.on('hide.bs.collapse', function(e) {
            var $el = $(e.target),
                action = 'close';

              ASAM.nav_menus.toggle_active($el, action);

          });
        });

      },
      toggle_active: function($collapse, action) {
        var $menu_item = $collapse.parents(ASAM.nav_menus.vars.menuItem),
            $menu_items = $menu_item.parents(ASAM.nav_menus.vars.nav).find(ASAM.nav_menus.vars.menuItem),
            $menu_item_before = $menu_item.prevAll(ASAM.nav_menus.vars.menuItem);

        if (action === 'open') {
            $menu_items.removeClass(ASAM.nav_menus.vars.activeClass);
            $menu_item.addClass(ASAM.nav_menus.vars.activeClass);
            $menu_item.addClass(ASAM.nav_menus.vars.openClass);
            $menu_item_before.addClass(ASAM.nav_menus.vars.activeClass);
        }
        else if(action === 'close') {
            $menu_item.removeClass(ASAM.nav_menus.vars.activeClass);
            $menu_item.removeClass(ASAM.nav_menus.vars.openClass);
            $menu_items.removeClass(ASAM.nav_menus.vars.activeClass);
        }
      },
      animate_menu: function($nav) {
        if(!$nav.length) {return;}

        ASAM.nav_menus.vars.items = $nav.children('.menu-item');
        // console.log(ASAM.nav_menus.vars.items);

        ASAM.nav_menus.animate_menu_item(0);


      },
      animate_menu_item: function(item_n) {
        var $this_item = $(ASAM.nav_menus.vars.items[item_n]),
            $prev_item = $(ASAM.nav_menus.vars.items[item_n-1]);

        ASAM.nav_menus.vars.menuAnimateLoop = setTimeout(function() {
          $prev_item.removeClass('active');
          $this_item.addClass('active');
          ASAM.nav_menus.animate_menu_item(item_n+1);
        }, 500);

        if(item_n > (ASAM.nav_menus.vars.items.length)) {
          ASAM.nav_menus.clear_animate_menu(ASAM.nav_menus.vars.menuAnimateLoop);
          // clearTimeout(ASAM.nav_menus.vars.menuAnimateLoop);
          $(ASAM.nav_menus.vars.items[item_n-1]).removeClass('active');
          // $(ASAM.nav_menus.vars.items).removeClass('active');
          // $(ASAM.nav_menus.vars.items[0]).addClass('active');
        }
      },
      clear_animate_menu: function($timeout) {
        clearTimeout($timeout);
        $(ASAM.nav_menus.vars.items).removeClass('active');
        $(ASAM.nav_menus.vars.items[0]).addClass('active');
      },
      nav_controls: function() {
        var $btns = $('.toggle-menu-btn'),
            $site_header = $('#site-header');

        $btns.each(function() {
          var $btn = $(this),
              $target = $($btn.data('target'));

          if($target.length) {
            $btn.click(function(e) {
              e.preventDefault();

              ASAM.nav_menus.toggle_nav_state($target);

            });

          }
        });

        $(document).on('click', function(e) {

          if ($site_header.has(e.target).length === 0 && $site_header.hasClass('open') && !$site_header.hasClass('static')) {
            $site_header.removeClass('open').addClass('closed');
          }
        });

      },
      toggle_nav_state: function($nav) {
        if ($nav.hasClass('closed')) {
          var $main_nav = $(ASAM.nav_menus.vars.mainNav).find(ASAM.nav_menus.vars.nav);

          $nav.addClass('open').removeClass('closed');

          // ASAM.nav_menus.animate_menu($main_nav);
        }
        else if($nav.hasClass('open')) {
          $nav.removeClass('open').addClass('closed');
          ASAM.nav_menus.clear_animate_menu(ASAM.nav_menus.vars.menuAnimateLoop);
          // clearTimeout(ASAM.nav_menus.vars.menuAnimateLoop);
        }
      }
    },

    sliders: {
      vars: {
        sliderContainer: '.post-slider-container',
        navArrows: '.slider-nav',
        nextArrow: '.slider-nav__next',
        prevArrow: '.slider-nav__prev',
        navDots: '.slider-dots'
      },
      init: function() {
        var $sliders = $('.slider');

        $sliders.each(function() {
          var $slider = $(this),
              $slider_container = $slider.parents(ASAM.sliders.vars.sliderContainer);

          if ($slider.hasClass('flex-slider')) {
            var section_h = Math.round(($(window).outerHeight() / 3) * 100) / 100;
            $slider_container.css({
              'height' : section_h,
              'overflow' : 'hidden'
            });

            ASAM.sliders.create_slider($slider);
          }
          else {
            ASAM.sliders.create_slider($slider);
          }

        });
      },
      create_slider: function($slider) {
        var $slider_container = $slider.parents(ASAM.sliders.vars.sliderContainer),
            $navArrows = $slider_container.find(ASAM.sliders.vars.navArrows),
            $nextArrow = $slider.find(ASAM.sliders.vars.nextArrow),
            $prevArrow = $slider.find(ASAM.sliders.vars.prevArrow),
            $navDots = $slider_container.find(ASAM.sliders.vars.navDots);

        $slider.on('initialized.owl.carousel', function(event) {
          $slider_container.removeAttr('style');
        });

        $slider.owlCarousel({
          items : 1,
          navContainer : ASAM.sliders.vars.navArrows,
          dotsContainer: ASAM.sliders.vars.navDots,
          dots: true,
          nav: true,
          navClass : ['slider-nav nav-prev', 'slider-nav nav-next'],
          navText : ['', ''],
          dotClass: 'slider-dot',
          autoplay: true,
          autoplayTimeout: 9000,
          autoplaySpeed: 5000,
          navSpeed: 5000,
          dotsSpeed: 5000,
          // smartSpeed: 5000,
          loop: true

        });

      }
    },

    ajax: {
        vars: {
            loader : '.ajax-html-loader',
        },
        
        init: function() {
            $(ASAM.ajax.vars.loader).each(function(){
                var $loader = $(this);
                
                $loader.ajax_html({
                    ajax_action         :   $loader.data('action'),
                    load_on_init        :   $loader.hasClass('load-on-init'),
                    include_masonry     :   $loader.hasClass('masonry-loader'),
                    infinite_scroll     :   $loader.find('form').hasClass('infinite_scroll'),
                    on_data             :   function(data) {
                      // console.log(data);
                      if (!data.auto_load_more) {                        
                        ASAM.helpers.update_url(data.update_url);
                      }
                      ASAM.ajax.update_paged(data, $loader);
                      // ASAM.ajax.update_filters();
                    },
                    on_clear            :   function(wrap) {
                        
                    },
                    on_append           :   function(item, wrap) {},
                    on_fragment         :   function(key, el) {
                        var $target = $(key);
                        if(el && key === '.archive-pagination-wrap' && !$target.length) {
                            var $pg_wrap = $loader.find('.pagination-insert');
                            $pg_wrap.append(el);
                        }
                        ASAM.ajax.ajax_form($loader);
                    },
                    on_complete         :   function(e, wrap, posts) {
                      var $form = $loader.find('form');

                      $form.removeClass('infinite-loading');
                    }
                });
                
                ASAM.ajax.ajax_form($loader);
                ASAM.ajax.ajax_infinite_scroll($loader);
            });

            ASAM.ajax.do_filter_check();
        },
        
        ajax_form : function ($loader) {
            $loader.find('.filter-form').each(function(){
                var $form = $(this);
                
                // $form.on('submit', function() {
                //   ASAM.ajax.update_filters();
                // });

                if(!$form.hasClass('ajax-form-init')) {
                    var $reset_btn = $form.find('.form-reset');
                    
                    $reset_btn.click(function(){
                        $form.trigger("reset");
                        $form.submit();
                    });
                    
                    $form.on('reset', function(event) {
                        $form.find('[name="paged"]').val(1);
                    });
                }
                
                var $pg_btns = $form.find('.pagination').find('[data-pg]');
                
                if(typeof $pg_btns !== 'undefined' && $pg_btns.length) {
                    $pg_btns.click(function(e){
                        e.preventDefault();
                        
                        var $btn         = $(this),
                            my_page      = $btn.data('pg'),
                            $form        = $btn.closest('form'),
                            $match_btns  = $form.find('.page-num[data-pg="' + my_page + '"]'),
                            $paged_field = $form.find('[name="paged"]');
                        
                        $pg_btns.removeClass('active');
                        $match_btns.addClass('active');
                        
                        // var root_path = window.location.protocol + '//' +window.location.host + window.location.pathname,
                        //     url = root_path + '?pg=' + my_page;
                        // history.replaceState(null, null, reset_path);
                        // ASAM.helpers.update_url(url);

                        if(parseInt($paged_field.val()) !== parseInt( my_page )) {
                            $paged_field.val(my_page).trigger('change');
                        }

                        $("html, body").animate({ scrollTop: $('.ajax-html-loader').offset().top - 50 }, "slow");
                    });
                }
                
                if($form.hasClass('auto-submit')) {
                    $form.find('input, select').each(function() {
                        var $el = $(this);
                        
                        if(!$el.hasClass('ajax-form-init')) {
                            $el.on('change', function(){
                                var $input = $(this);
                                
                                if($input.attr('name') !== 'paged') {
                                    $form.find('[name="paged"]').val(1);
                                    // $form.find('[name="paged"].page-num').filter('[value="1"]').attr('checked', 'checked');
                                }
                                
                                $form.submit();
                            });
                            
                            $el.addClass('ajax-form-init');
                        }
                    });
                }
                
                $form.addClass('ajax-form-init');
            });
        },

        ajax_filters: function() {
          var $loader = $(ASAM.ajax.vars.loader),
              $filterContainers = $loader.find('.filters');

          $filterContainers.each(function() {
            var $filterWrap = $(this),
                $filter_els = $filterWrap.find('.filter-el'),
                $is_select = $filterWrap.is('select');


            if (window.matchMedia("(max-width: 768px)").matches) {

              if ($filterWrap.hasClass('filters-desktop')) {
                $filter_els.attr('disabled', true);
              }
              else {
                if (!$filter_els.hasClass('term-disabled')) {
                  $filter_els.attr('disabled', false);
                }
                $filterWrap.attr('disabled', false);
              }

            } else {

              if ($filterWrap.hasClass('filters-mobile')) {
                $filter_els.attr('disabled', true);
                $filterWrap.attr('disabled', true);
              }
              else {
                if (!$filter_els.hasClass('term-disabled')) {
                  $filter_els.attr('disabled', false);
                }
              }

            }

          });
        },

        ajax_infinite_scroll: function($loader) {
          $loader.find('.filter-form').each(function(){
            var $form = $(this);

            if ($form.hasClass('infinite-scroll')) {
                  // $next_page = $form.find('[name="next_page"]');
              $(window).on('scroll', function(e) {
                debounce(ASAM.ajax.infinite_scroll_check($loader));
              });
            }

          });
        },
        infinite_scroll_check: function($loader) {
          if($(window).scrollTop() + $(window).height() > $(document).height() - 200) {
            var $el = $loader.find('form');
              if (!$el.hasClass('infinite-loading')) {
               // console.log("bottom!");

               ASAM.ajax.get_infinite_next_page($el);
              }
           }

        },
        get_infinite_next_page: function($form) {
          var $infinite_load_more = $form.find('[name="infinite_load_more"]'),
              $auto_load_more = $form.find('[name="auto_load_more"]'),
              $paged = $form.find('[name="paged"]');

          if ($infinite_load_more.val() > 0 && !$infinite_load_more.is('disabled')) {
            $form.addClass('infinite-loading');

            $paged.val($infinite_load_more.val());
            $auto_load_more.val(1);
            
            $form.submit();

            $auto_load_more.val(0);
          }

        },
        update_paged: function(data, $loader) {
          $loader.find('.filter-form').each(function(){
            var $form = $(this),
                $paged = $form.find('[name="paged"]'),
                $next_page = $form.find('[name="next_page"]');

            if (data.infinite_scroll) {
              $next_page.val(data.next_page);
            }
          });
        },

        do_filter_check : function() {
          var $inputs = $('input.input-filter');

          $inputs.each(function() {
            var $input = $(this);

            $input.on('change', function() {
              ASAM.ajax.update_filters($input);
            });
          });
        },

        update_filters : function($el) {
          var $filter_wrap = $el.parents('.filters'),
              $inputs = $filter_wrap.find('input');

          $inputs.each(function() {
            var $input = $(this);

            if ($input.is(':checked')) {
              $input.addClass('active').removeClass('inactive');
              // console.log($input);  
            }
            else {
              $input.removeClass('active').addClass('inactive');
            }
            
          });


          // var $filter_wraps = $('.filters');

          // $filter_wraps.each(function() {
          //   var $filter_wrap = $(this),
          //       $filters = $filter_wrap.find('.filter-option');

          //   $filters.each(function() {
          //     var $filter = $(this),
          //         $input = $filter.find('input');

          //   });
          // });
        },
        fade_in_results: function($results) {
            var ani_timing = 100;

            $results.each(function(key, item) {
                var time = ani_timing * key;

                setTimeout(function() {
                    $(item).addClass('in');
                }, time);
            });
        },
    },

    forms: {
      vars : {
        inputSets : '.input-set',
        activeClass : 'input-set__focus',
        autoFocusClass : 'autofocus',
        filledClass : 'input-set__filled'
      },
      init: function() {
        var wpcf7Elm = document.querySelector( '.wpcf7' ),
            $submitBtn = $(wpcf7Elm).find('[type="submit"]');

        ASAM.forms.input_set_animation();
        ASAM.forms.search_field();

        if(wpcf7Elm) {
          wpcf7Elm.addEventListener( 'wpcf7invalid', function(event) {
              ASAM.forms.validate_wpcf7_forms(event);
          }, false );

          wpcf7Elm.addEventListener('wpcf7mailsent', function( event ) {
              ASAM.forms.sent_wpcf7_forms(event);
          }, false );
              
          $submitBtn.on('click', function(e) {
            e.preventDefault();
            var $form = $(this).parents('form.wpcf7-form');
            ASAM.forms.submit_wpcf7_forms($(this), $form);
          });
        } 
      },

      custom_select: function() {
        var $selects = $('.b-select');

        $selects.each(function() {
          $select = $(this);

          $select.selectpicker({
            style : 'btn-select'
          });

          // if (/iPad|iPhone|iPod|android/.test(navigator.userAgent)) {
          //   $select.selectpicker('mobile');
          // }
        });
      },

      search_field: function() {
        var $search_fields = $('.search-field');

        $search_fields.each(function() {
          var $search_field = $(this),
              m_placeholder = $search_field.data('placeholder'),
              placeholder = $search_field.data('placeholder-lg');

          if (window.matchMedia("(max-width: 991px)").matches) {

            if (m_placeholder !== 'undefined' && m_placeholder !== '') {
              $search_field.attr('placeholder', m_placeholder);
            }
              console.log(m_placeholder);
          } 
          else {
            
            if (placeholder !== 'undefined' && placeholder !== '') {
              $search_field.attr('placeholder', placeholder);

            }
          }
        });
      },
      input_set_animation: function() {
        var $inputSets = $(ASAM.forms.vars.inputSets);

        $inputSets.each(function() {
          var $inputSet = $(this),
            $label = $inputSet.find('.input-set__label'),
            $field = $inputSet.find('.input-set__field'),
            has_value = false;

          $field.focus(function() {
            // onInputFocus($inputSet);
            ASAM.forms.on_input_focus($inputSet);
          });

          $field.blur(function() {
            has_value = $field.val() !== '' ? true : false;
            // onInputBlur($inputSet, has_value);
            ASAM.forms.on_input_blur($inputSet, has_value);
          });
        });
      },

      on_input_focus: function($input_set) {
          $input_set.addClass(ASAM.forms.vars.activeClass);
      },

      on_input_blur: function($input_set, condition) {
        $input_set.removeClass(ASAM.forms.vars.activeClass)
                  .removeClass(ASAM.forms.vars.autoFocusClass)
                  .toggleClass(ASAM.forms.vars.filledClass, condition);
      },

      validate_wpcf7_forms: function(event) {
        var $form = $(event.target).find('form'),
            $invalidFields = $form.find('.wpcf7-not-valid'),
            $btn = $form.find('[type="submit"]'),
            btn_label = $btn.data('label'),
            $formGroups = $form.find('.form-group'),
            fieldErrorClass = 'field-error';

        var required = 'required *';

        $btn.html(btn_label);

        $formGroups.each(function() {
          var $formGroup = $(this),
              $invalidField = $formGroup.find('.wpcf7-not-valid'),
              $input = $formGroup.find('.wpcf7-form-control');
              // $select = $formGroup.find('select.wpcf7-not-valid'),
              // selectTitleWrap = $formGroup.find('.filter-option'),
              // $textWrap = $formGroup.find('.text-wrap'),
              // selectTitle = $select.data('org-title'),
            // fieldTitle = $input.data('org-title');

          var is_inputSet = $formGroup.hasClass('input-set'),
            is_inputSelect = $formGroup.hasClass('input-select'),
            is_invalid = false;

          if($invalidField.length) {
            is_invalid = true;
          }

          $formGroup.toggleClass(fieldErrorClass, is_invalid);

          // if(is_invalid && is_inputSet) {
          //   var textWrapContent = fieldTitle.replace('*', ''),
          //     requiredText = textWrapContent + required;
            
          //   $textWrap.html(requiredText);

          // }
          // else if(!is_invalid && is_inputSet) {
          //   $textWrap.html(fieldTitle);
          // }

          // if(is_invalid && is_inputSelect) {
          //   var selectWrapContent = selectTitle.replace('*', ''),
          //     selectRequiredText = selectWrapContent + required;

          //   selectTitleWrap.html(selectRequiredText);
          //   // console.log(fieldTitle);
          // }
          // else if(!is_invalid && is_inputSelect) {
          //   selectTitleWrap.html(selectTitle);
          // }
        });

          $invalidFields[0].focus();

      },

      submit_wpcf7_forms: function(btn, form) {

        btn.html('Sending');
        form.submit();

      },

      sent_wpcf7_forms: function(e) {
          var $form = $(e.target).find('form'),
              $btn = $form.find('[type="submit"]'),
              label = $btn.data('label');

          $btn.html(label);

          // if($redirect_field.length) {
          //     var redirect_url = window.location.protocol + '//' + window.location.host + $redirect_field.val();

          //     window.location.href = redirect_url;
          // }
          
      },
    },

    helpers : {
      oPageInfo : {
        title: null,
        url: location.href,
      },
      update_url: function(url) {
        if (url) {
          history.pushState(ASAM.helpers.oPageInfo, ASAM.helpers.oPageInfo.title, url);
        }
      },
      smoothScroll: function(selector) {
        var target = selector,
          yTo = 0,
          target_pos = null,
          anchor_y = null,
          w_height = window.innerHeight,
          y = window.pageYOffset;

        if(selector === 'top') {
          target_pos = 0;
        } 
        else if(selector === 'page') {
          target_pos = (y + w_height) + w_height;
        } 
        else {
          
          if(typeof selector === 'string') {
            target = $(selector);
          }
          
          if(!target.length) {
            return;
          }

          target_pos =  target.offset().top;
          anchor_y = target.data('anchor-y');

        }

        if(typeof anchor_y !== 'undefined' && anchor_y !== null) {
          target_pos = ((anchor_y/100) * windowHeight());
          // clog('target_pos:', target_pos);
        }

        var header_comp = 0;

        if(window.matchMedia("(min-width: 991.98px)").matches) {
          header_comp = 80;
        }
        // clog('yTo',yTo);
        // yTo = target_pos;
        yTo = target_pos - header_comp;
        // clog('header_comp', header_comp);
        // clog('yTo after', yTo);

        $('html,body').animate({
          scrollTop: yTo
        }, 500);
        // }
      },
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = ASAM;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
