(function ( $ ) {

    $.fn.lightzoom = function(options) {

        var settings = $.extend({
            zoomPower   : 3,
            glassWidth   : 300,
            glassHeight   :200
        }, options);

        var halfWidth= settings.glassWidth /2;
        var quarterWidth = settings.glassWidth/4;

        var halfHeight = settings.glassHeight /2;
        var quarterHeight = settings.glassHeight/4;

        var zoomPower = settings.zoomPower;

        $("body").append('<div id="glass"></div>');
        $('html > head').append($('<style> #glass{width: '+settings.glassWidth+'px; height: '+settings.glassHeight+'px;}</style>'));


        var faker;
        var obj=this;

        $("#glass").mousemove(function(event) {
            var obj=this.targ;
            event.target=obj;
            faker(event,obj);
        });

        this.mousemove(function(event) {
            faker(event,this);
        });

        faker=function(event,obj) {
            //console.log(obj);
            document.getElementById('glass').targ=obj;
            var mx = event.pageX;
            var my = event.pageY;
            var bounding = obj.getBoundingClientRect();
            var w = bounding.width;
            var h = bounding.height;
            var objOffset  = $(obj).offset();         
            var ol = objOffset.left;
            var ot = objOffset.top;
            if(mx > (ol + ($('#glass').width() / 2)) &&  mx < ol + (w - $('#glass').width() / 2)  && ot + (($('#glass').height() / 2)) < my  &&  ot+h > (my + ($('#glass').height() / 2)) ) {
                offsetXfixer = ((mx-ol - w/2)/(w/2))*quarterWidth;
                offsetYfixer = ((my-ot - h/2)/(h/2))*quarterHeight;
                var cx = (((mx - ol + offsetXfixer ) / w)) * 100;
                var cy = (((my - ot + offsetYfixer ) / h)) * 100;
                my -= halfHeight;
                mx -= halfWidth;
                $("#glass").css({
                    "top": (my),
                    "left": (mx),
                    "background-image" : " url('" + obj.src + "')",
                    "background-size" : (w * zoomPower) + "px " + (h * zoomPower) + "px",
                    "background-position": cx + "% " + cy + "%",
                    "display" : "inline-block"
                });
                $("body").css("cursor","none");
            }else {
                $("#glass").css("display", "none");
                $("body").css("cursor","default");
            }
        };
        return this;
    };
}( jQuery ));